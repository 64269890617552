/* SmsPortal.css */

.container {
    max-width: 780px;
    margin: 0 auto;
    padding-top: 5px;
    font-family: Arial, sans-serif;
  }
  
  h1, h2 {
    color: #4CAF50;
  }
  
  .input-group {
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="email"] {
    width: calc(100% - 20px);
    padding: 8px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
  }
  
  li:last-child {
    border-bottom: none;
  }
  