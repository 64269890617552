.circular-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f0f0f0;
    border: 2px solid #007bff;
    cursor: pointer;
    position: relative;
  }
  
  .circular-checkbox input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
    z-index: 1;
  }
  
  .circular-checkbox .icon-done {
    display: none;
    font-size: 18px;
    color: #007bff;
  }
  
  .circular-checkbox input:checked + .icon-done {
    display: block;
  }
  