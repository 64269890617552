.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 550px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.popup-inner .form-group {
  margin-bottom: 15px;
}

.popup-inner .btn {
  margin: 5px;
}


.navbar-brand {
  display: flex;
  align-items: center;
}

.nav-item {
  margin-left: 15px;
}

.ml-auto {
  margin-left: auto !important;
}

/* login and register color */

body {
  background: #f0f2f5;
}

.card {
  border-radius: 15px;
}

.auth-container {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #0275d8;
  border-color: #0275d8;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.btn-primary:hover {
  background-color: #0256a7;
  border-color: #0256a7;
}

h1, h2 {
  color: #333;
}

.text-center {
  color: #666;
}

.text-primary {
  color: #0275d8 !important;
}

.text-danger {
  color: #d9534f !important;
}


.navbar-toggler {
  display: block;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: column;
}

.navbar-nav .nav-item {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .label {
    font-size: 24px; /* Smaller font size for mobile */
    padding: 8px 16px; /* Smaller padding for mobile */
    margin: 10px 0; /* Smaller margin for mobile */
  }

  /* Additional mobile-specific styles */
  .container {
    padding: 10px; /* Ensure container has padding within mobile view */
  }

  .button {
    width: 100%; /* Buttons occupy full width in mobile view */
    padding: 10px; /* Adequate padding for touch screens */
  }
  h2 {
    font-size: 24px; /* Smaller font size for mobile */
  }

  .card {
    padding: 10px; /* Reduce padding for .card */
  }

  .table {
    font-size: 14px; 
    overflow-x: auto; 
    display: block; 
  }

  .table thead, .table tbody, .table tr {
    display: block; 
  }

  .table th, .table td {
    /* display: inline-block;  */
    width: 100%;
    box-sizing: border-box; 
    padding: 10px; 
  }

  .btn {
    font-size: 14px; /* Smaller font size for buttons */
    padding: 5px; /* Smaller padding for buttons */
  }
  .table-container {
    width: 100%;
  }
  .table-container {
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  
  table {
    min-width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
  }
  .phone{
    font-size: 9px !important;
  }
  .edit-btn {
    display: block;
  }
  tbody {
    min-width: 100%;
  }
  .table td
  {
    font-size: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .table th{
    padding-left: 10px !important;
  }
}

.phone-label {
  display: block;

  text-align: left;
}
